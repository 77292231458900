import "./home.css"
import '@google/model-viewer'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

//Components
import Header from '../../components/Header'

// Images
import back from "../../assets/img/back.jpg"
import tuzzle_home from "../../assets/img/tuzzle_home.png"
import tuzzle_play from "../../assets/img/tuzzle_play.png"

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = async () => {

    }

    render() {
        return (
            <div className="container" style={{ backgroundImage: `url('${back}')` }}>
                <div className="content">
                    <Header />
                    <div className="front">
                        <h1 className="title">Discover tuzzle</h1>
                        <div className="tuzzle_holder">
                            <img src={tuzzle_home} className="img_tuzzle" alt="tuzzle home" />
                            <img src={tuzzle_play} className="img_tuzzle" alt="tuzzle play" />
                        </div>
                        <div className="text">
                            <p>An addicitve puzzle game soon available on Appstore and Playstore</p>
                        </div>
                        <h1 className="title" style={{marginTop: "100px"}}>Development services</h1>
                        <div className="text">
                            <p>
                                Fullstack and Blockchain (Ethereum, Polygon & Tezos) development
                            </p><p>
                                Let's get in touch : <a href="mailTo:contact@level426.xyz">contact@level426.xyz</a>
                            </p>
                            <hr />
                            <p>
                                <small>
                                    <a href="/privacy">Privacy</a> ©LEVEL426 2024. Simplified joint-stock company, registered B 920 591 021 to the RCS of Montpellier.
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Home)