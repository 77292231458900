import "./privacy.css"
import '@google/model-viewer'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = async () => {

    }

    render() {
        return (
            <div className="container">
                <div className="content">
                    <div className="front">
                        <h1 className="title" style={{ marginTop: "80px" }}>PRIVACY POLICY</h1>
                        <div className="text">
                            <p>
                                <small>May 28th, 2024</small>
                            </p>
                        </div>
                        <div className="text">
                            <p>
                                At Level426 do not collect any personal data without you be informed.
                                This privacy policy explains the special attention we have for the users of our websites, applications and services and their personal data.
                            </p>
                        </div>
                        <h2 className="title" style={{ marginTop: "30px" }}>Data controller</h2>
                        <div className="text">
                            <p>
                                LEVEL426 SAS, represented by its President, is registered B 920 591 021 with the Montpellier RCS.
                            </p>
                            <p>
                                SIRET: 92059102100018
                            </p>
                            <p>
                                VAT: FR36920591021
                            </p>
                        </div>
                        <h2 className="title" style={{ marginTop: "30px" }}>Data protection contact</h2>
                        <div className="text">
                            <p>
                                For any exercise of your rights or for any questions relating to the protection of personal data and privacy, you can contact us:
                            </p>
                            <p>
                                <a href="mailTo:contact@level426.xyz">contact@level426.xyz</a>
                            </p>
                        </div>
                        <h2 className="title" style={{ marginTop: "30px" }}>Collection of personal data</h2>
                        <div className="text">
                            <p>
                                By personal data, we mean any information that allows you to be identified either directly or indirectly, within the meaning of article 4.1 of the GDPR.
                            </p>
                            <p>
                                On our websites, applications and services, data collection is limited to the strict minimum depending on the service we offer you.
                            </p>
                            <p>
                                By default, we do not collect any personal data on our websites, applications and services (the IP address is anonymized by obfuscation of the last 3 digits). This means that you can use our websites, applications and services without sharing any personal data with us.
                            </p>
                            <p>
                                When features of our applications require personal data to provide the service, such as creating an account or subscribing to a newsletter, we give you at the time of collection all the information on the processing(s) implemented, as well as your rights and how to exercise them.
                            </p>
                            <p>
                                We may also collect technical data related to your equipment, your browser or your use of our websites, applications or services to optimize your browsing according to your technical environment. This data does not allow you to be identified personally, nor directly or indirectly.
                            </p>
                            <p>
                                Whenever we collect personal data, we only collect the data strictly necessary for the purpose of the service we offer and to which you wish to subscribe.
                            </p>
                            <p>
                                We do not share or disclose any personal data to third parties
                            </p>
                        </div>
                        <h2 className="title" style={{ marginTop: "30px" }}>Legal basis of processing</h2>
                        <div className="text">
                            <p>
                                The processing of your personal data on our websites, applications and services may be justified:
                            </p>
                            <p>
                                Either on your consent. This can be withdrawn at any time, for example to notify us of your wish to no longer receive a newsletter.
                            </p>
                            <p>
                                Either on a contract or conditions of use that you accept when creating your LoyalBuds account.
                            </p>
                            <p>
                                Either on a legal obligation imposed on our company.
                            </p>
                            <p>
                                Either on the legitimate interest of the company.
                            </p>
                            <p>
                                In all cases, the legal basis for processing your personal data is indicated at the time of collection of your personal data.
                            </p>
                        </div>
                        <h2 className="title" style={{ marginTop: "30px" }}>Personal rights</h2>
                        <div className="text">
                            <p>
                                You have a right of access, rectification, erasure, limitation, as well as a right to portability of your data. You can object, subject to providing a reason legitimate, that these data are subject to processing.
                            </p>
                            <p>
                                You can exercise your rights:
                            </p>
                            <p>
                                By sending us an email to the following address <a href="mailTo:contact@level426.xyz">contact@level426.xyz</a>
                            </p>
                            <p>
                                If you are dissatisfied with the way your personal data or your requests under the GDPR or the Data Protection Act are processed, you can lodge a complaint with the Commission Nationale de l'Informatique et of Liberties (CNIL) or with the authority responsible for data protection in your country.
                            </p>
                        </div>
                        <div className="text">
                            <hr />
                            <p>
                                <small>
                                    <a href="/privacy">Privacy</a> ©LEVEL426 2024. Simplified joint-stock company, registered B 920 591 021 to the RCS of Montpellier.
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default withTranslation()(Privacy)