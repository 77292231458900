import "./header.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// Images
import logoWhite from "../../assets/img/logo_white.png"

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = async() => {
    }

    render() {
        return (
            <React.Fragment>
                <header>
                    <img src={logoWhite} className="logo" alt="level426 logo"/>
                </header>
            </React.Fragment>
        );
    }
}

export default withTranslation()(Header)